<template>
    <div v-if="isEnabled">
        <slot></slot>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'p-feature-flag',
    props: {
        name: String,
        redirect: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapGetters(['featureFlagEnabled']),
        isEnabled() {
            return this.featureFlagEnabled(this.name);
        }
    },
    updated: async function () {
        if (this.isEnabled == undefined && this.redirect) {
            this.$router.replace('/');
        }
    }
};
</script>

<style>
</style>
